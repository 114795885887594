


































































































import { Component, Ref, Vue, Prop, ModelSync } from "vue-property-decorator";

import {
    ComboBoxItem,
    BasicFormInterface,
    FormBtnAction,
    FormActions
} from "@/interfaces";

@Component({
    components: {
        CalendarRangeInput: () =>
            import("@/components/layout/CalendarRangeInput.vue"),
        SelectActivator: () => import("@/components/forms/SelectActivator.vue")
    }
})
export default class BasicForm extends Vue {
    @ModelSync("form", "change", {
        type: Object,
        default: {
            valid: false,
            variable: "",
            timeRange: "",
            customTimeRange: []
        }
    })
    formValues!: BasicFormInterface;

    @Ref("form") readonly formActions!: FormActions;

    @Prop({ type: Array, required: false, default: null })
    variablesOptions!: ComboBoxItem[] | null;

    @Prop({ type: Array, required: false, default: null })
    timeRangesOptions!: ComboBoxItem[] | null;

    @Prop({ type: Array, required: false, default: null })
    predictionsOptions!: ComboBoxItem[] | null;

    @Prop({ type: Array, required: false, default: null })
    simulationsOptions!: ComboBoxItem[] | null;

    customTimeRangeDisabled = true;
    formRules = {
        variable: [v => !!v || this.$t("damVariablesDialog.variableRequired")],
        timeRange: [
            v => !!v || this.$t("damVariablesDialog.timeRangeRequired")
        ],
        customTimeRange: [v => this.customTimeRangeRule(v)]
    };

    predictions = false;
    simulations = false;

    predictionsTexts = {
        label: this.$t("form.predictions.predictions"),
        subheader: this.$t("form.predictions.forecastType")
    };

    simulationsTexts = {
        label: this.$t("form.simulations.simulations"),
        subheader: this.$t("form.simulations.simulationType")
    };

    customTimeRangeRule(v) {
        if (this.formValues.timeRange === "custom" && v === "") {
            return String(
                this.$t("damVariablesDialog.customTimeRangeRequired")
            );
        } else {
            return true;
        }
    }

    /**
     * Check if the custom time range is selected
     */
    checkCustomTimeRange(): void {
        if (this.formValues.timeRange === "custom") {
            this.customTimeRangeDisabled = false;
        } else {
            this.customTimeRangeDisabled = true;
        }
    }

    handlePredictionOnSelect(value: number): void {
        this.formValues.forecasting = value;
    }

    handleSimulationOnSelect(value: number): void {
        this.formValues.simulation = value;
    }

    /**
     * Handle the form submit
     */
    handleRefresh(): void {
        if (!this.formActions.validate()) return;

        const { startDate, endDate } = this.getDateLimits();

        let params: FormBtnAction = {
            variable: this.formValues.variable,
            startDate,
            endDate
        };

        /**
         * Forecasting & Simulation params
         */
        params = {
            ...params,
            forecasting: this.predictions
                ? this.formValues.forecasting
                : undefined,
            simulation: this.simulations
                ? this.formValues.simulation
                : undefined
        };

        /**
         * Emit event
         */
        this.$emit("on-refresh", params);
    }

    /**
     * Reset validations
     */
    resetValidation(): void {
        this.formActions.resetValidation();
    }

    /**
     * Get start and end dates
     */
    getDateLimits() {
        let startDate: Date, endDate: Date;

        if (this.formValues.timeRange === "custom") {
            this.formValues.customTimeRange.sort((a, b) => {
                return new Date(a).getTime() - new Date(b).getTime();
            });
            startDate = new Date(this.formValues.customTimeRange[0]);
            endDate = new Date(
                new Date(this.formValues.customTimeRange[1]).setHours(
                    23,
                    59,
                    59
                )
            );
        } else {
            startDate = new Date(
                new Date().setDate(
                    new Date().getDate() - parseInt(this.formValues.timeRange)
                )
            );

            endDate = new Date(new Date().setHours(23, 59, 59));
        }

        return { startDate, endDate };
    }

    get predictionsShown() {
        return this.predictionsOptions && this.predictionsOptions.length;
    }

    get simulationsShown() {
        return this.simulationsOptions && this.simulationsOptions.length;
    }
}
